exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-brands-jsx": () => import("./../../../src/pages/brands.jsx" /* webpackChunkName: "component---src-pages-brands-jsx" */),
  "component---src-pages-checkout-jsx": () => import("./../../../src/pages/checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-return-policy-jsx": () => import("./../../../src/pages/return-policy.jsx" /* webpackChunkName: "component---src-pages-return-policy-jsx" */),
  "component---src-pages-shipping-jsx": () => import("./../../../src/pages/shipping.jsx" /* webpackChunkName: "component---src-pages-shipping-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-templates-manufacturer-index-jsx": () => import("./../../../src/templates/manufacturer/index.jsx" /* webpackChunkName: "component---src-templates-manufacturer-index-jsx" */),
  "component---src-templates-product-category-index-jsx": () => import("./../../../src/templates/productCategory/index.jsx" /* webpackChunkName: "component---src-templates-product-category-index-jsx" */),
  "component---src-templates-product-index-jsx": () => import("./../../../src/templates/product/index.jsx" /* webpackChunkName: "component---src-templates-product-index-jsx" */)
}

