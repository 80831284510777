import React from 'react';

import './src/styles/global.css';
import { StoreContextProvider } from './src/context/store-context';

const scrollToAnchor = (location, mainNavHeight = 0) => {
  if (location && location.hash) {
    const item = document?.querySelector(`${location.hash}`)?.offsetTop;

    window.scrollTo({
      top: item - mainNavHeight,
      behavior: 'smooth',
    });
  }

  return true;
};

export const wrapRootElement = ({ element }) => (
  <StoreContextProvider>{element}</StoreContextProvider>
);

export const onRouteUpdate = ({ location }) => {
  scrollToAnchor(location, 88);
};
