import React, { createContext, useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const isBrowser = typeof window !== 'undefined';
const defaultState = {
  cart: isBrowser
    ? JSON.parse(window.localStorage.getItem('cart') || null)
    : null,
};

const StoreContext = createContext(defaultState);

export const StoreContextProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(defaultState.cart);
  const [cartVisible, setCartVisible] = useState(false);
  const [chatLoaded, setChatLoaded] = useState(false);
  const chatBoxRef = useRef(null);

  const updateCartItems = () => {
    setCartItems(
      isBrowser ? JSON.parse(window.localStorage.getItem('cart') || null) : null
    );
  };

  const contextValues = {
    cartItems,
    setCartItems,
    updateCartItems,
    cartVisible,
    setCartVisible,
    chatLoaded,
    setChatLoaded,
    chatBoxRef,
  };

  return (
    <StoreContext.Provider value={contextValues}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStoreContext = () => {
  const context = useContext(StoreContext);

  if (context === undefined || context === null) {
    throw new Error(
      `useStoreContext must be called within StoreContextProvider`
    );
  }
  return context;
};

StoreContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
